let CSRF_TOKEN = '';
let select2DefaultOptions = {
	minimumResultsForSearch: -1,
	width: '100%'
};

/**
 * The main class of the JavaScript.
 *
 * @constructor
 */
function Main() {

	/**
	 * @type {Main} The instance of this class.
	 */
	let self = this;


	/**
	 * The Main constrcutor.
	 */
	this.init = function() {

		$('input[type="checkbox"]').dcCheckbox();
		$('input[type="radio"]').dcRadioButton();

		$('select').not('.select2-hidden-accessible').select2(select2DefaultOptions);

		this.registerOnClick('[data-message-toggle]', self.toggleMessageText);

		this.filterBox();
	};

	/**
	 * Register for an click event with a event handler.
	 *
	 * @param {string} selector The selector.
	 * @param {object} callback The callback.
	 */
	this.registerOnClick = function(selector, callback) {
		$(document).on('click', selector, callback);
	};

	/**
	 * Toggles the filter box arrow.
	 */
	this.filterBox = function() {

		let filterBoxInner = $('#filterBoxInner');
		let filterBoxToggle = $('.filter-box-toggle');

		filterBoxInner.on('shown.bs.collapse', function() {
			let selects = $(this).find('select');
			selects.select2('destroy');
			selects.not('.select2-hidden-accessible').select2(select2DefaultOptions);
		});

		filterBoxInner.on('show.bs.collapse', function() {
			filterBoxToggle.find('i.fa').removeClass('fa-caret-down').addClass('fa-caret-up');
		});
		filterBoxInner.on('hide.bs.collapse', function() {
			filterBoxToggle.find('i.fa').removeClass('fa-caret-up').addClass('fa-caret-down');
		});
	};



	/**
	 * Toggles the message preview and full text.
	 */
	this.toggleMessageText = function() {

		const id = this.dataset.messageToggle;
		const fullText = document.querySelector('[data-message-full="' + id + '"]')
		const previewText = document.querySelector('[data-message-preview="' + id + '"]')

		if(fullText.classList.contains('d-none') === true) {
			fullText.classList.remove('d-none');
			previewText.classList.add('d-none');
		}
		else {
			fullText.classList.add('d-none');
			previewText.classList.remove('d-none');
		}
	};


	// Call the class constructor
	this.init();
}


$(document).ready(function() {
	CSRF_TOKEN = $('input[name="csrf_token"]').val();
	new Main();
});